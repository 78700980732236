// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-contact-page-jsx": () => import("./../src/templates/contact-page.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-message-page-jsx": () => import("./../src/templates/message-page.jsx" /* webpackChunkName: "component---src-templates-message-page-jsx" */),
  "component---src-templates-news-page-jsx": () => import("./../src/templates/news-page.jsx" /* webpackChunkName: "component---src-templates-news-page-jsx" */),
  "component---src-templates-news-post-jsx": () => import("./../src/templates/news-post.jsx" /* webpackChunkName: "component---src-templates-news-post-jsx" */),
  "component---src-templates-product-item-jsx": () => import("./../src/templates/product-item.jsx" /* webpackChunkName: "component---src-templates-product-item-jsx" */),
  "component---src-templates-products-page-jsx": () => import("./../src/templates/products-page.jsx" /* webpackChunkName: "component---src-templates-products-page-jsx" */),
  "component---src-templates-static-page-jsx": () => import("./../src/templates/static-page.jsx" /* webpackChunkName: "component---src-templates-static-page-jsx" */),
  "component---src-templates-top-page-jsx": () => import("./../src/templates/top-page.jsx" /* webpackChunkName: "component---src-templates-top-page-jsx" */)
}

